module.exports = [{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://637547f1b4624a66ae727217fa1eeb33@o1090586.ingest.sentry.io/6106747","sampleRate":0.7,"environment":"main"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://leedsrunroutes.co.uk"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://production-lrr.stellate.sh/graphql","verbose":true,"schema":{"perPage":20,"timeout":60000,"requestConcurrency":5,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","previewRequestConcurrency":5},"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"type":{"AllSettings":{"excludeFieldNames":["generalSettingsEmail"]},"Article":{"limit":500},"Route":{"limit":500},"Comment":{"exclude":true},"GfEntry":{"exclude":true},"GfSubmittedEntry":{"exclude":true},"Post":{"exclude":true},"User":{"excludeFieldNames":["seo"]},"MediaItem":{"localFile":{"requestConcurrency":1,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"excludeFieldNames":["contentNodes","seo","ancestors","template","lastEditedBy","authorDatabaseId","authorId","contentTypeName","dateGmt","desiredSlug","enclosure","isContentNode","isTermNode","modified","modifiedGmt","parentDatabaseId","parentId","srcSet","parent","children"],"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"html":{"imageMaxWidth":700,"fallbackImageMaxWidth":700,"useGatsbyImage":true,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-gravity-forms/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://production-lrr.stellate.sh/graphql"},
    },{
      plugin: require('../plugins/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"leedsrunroutes.co.uk","trackOutboundLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Leeds Run Routes","short_name":"Leeds Run Routes","description":"Find run routes near you in Leeds.","lang":"en","start_url":"/","background_color":"#ffffff","theme_color":"#F16623","display":"standalone","icon":"src/assets/images/fav.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c4523423bd0c6c0c9d69aa3fb11027f6"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
