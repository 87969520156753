exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-leeds-running-newsletter-signup-js": () => import("./../../../src/pages/leeds-running-newsletter-signup.js" /* webpackChunkName: "component---src-pages-leeds-running-newsletter-signup-js" */),
  "component---src-templates-article-cats-js": () => import("./../../../src/templates/articleCats.js" /* webpackChunkName: "component---src-templates-article-cats-js" */),
  "component---src-templates-article-listing-js": () => import("./../../../src/templates/articleListing.js" /* webpackChunkName: "component---src-templates-article-listing-js" */),
  "component---src-templates-article-single-js": () => import("./../../../src/templates/articleSingle.js" /* webpackChunkName: "component---src-templates-article-single-js" */),
  "component---src-templates-article-tags-js": () => import("./../../../src/templates/articleTags.js" /* webpackChunkName: "component---src-templates-article-tags-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-route-listing-js": () => import("./../../../src/templates/routeListing.js" /* webpackChunkName: "component---src-templates-route-listing-js" */),
  "component---src-templates-route-locations-js": () => import("./../../../src/templates/routeLocations.js" /* webpackChunkName: "component---src-templates-route-locations-js" */),
  "component---src-templates-route-organisers-js": () => import("./../../../src/templates/routeOrganisers.js" /* webpackChunkName: "component---src-templates-route-organisers-js" */),
  "component---src-templates-route-single-js": () => import("./../../../src/templates/routeSingle.js" /* webpackChunkName: "component---src-templates-route-single-js" */),
  "component---src-templates-route-types-js": () => import("./../../../src/templates/routeTypes.js" /* webpackChunkName: "component---src-templates-route-types-js" */)
}

